import { useCallback, useEffect, useMemo, useState } from 'react';
import { usePubNub } from 'pubnub-react';
import {
  ActivityPayload,
  ActivitySubtype,
  SlideActionType,
  UseActivityProps,
  CustomMessageEvent,
  SlideBodyType,
} from '@domain/constants';
import {
  ActivitySelectors,
  StudentsSelectors,
  UserSelectors,
} from '@store/slices';
import { useSelector } from 'react-redux';
import { nanoid } from '@reduxjs/toolkit';
import { useLessons } from './useLessons';
import { useQuery } from '@tanstack/react-query';

export const useOpenQuestion = ({ channel, slideId }: UseActivityProps) => {
  const [responses, setResponse] = useState<
    {
      id: string;
      username: string;
      response: string;
      likes: { count: number; userIds: string[] };
    }[]
  >([]);
  const [showChoiceAward, setShowChoiceAward] = useState(false);
  const [favoriteResponses, setFavoriteResponses] = useState<
    | {
        username: string;
        response: string;
        likes: { count: number; userIds: string[] };
      }[]
    | undefined
  >(undefined);
  const students = useSelector(StudentsSelectors.getWaitingRoom);
  const { getContentResponses } = useLessons();

  const pubnub = usePubNub();
  const { maximumStepReached, currentStep, hasNavigatedBack } = useSelector(
    ActivitySelectors.getNavigationState
  );

  const userId = useSelector(UserSelectors.getId);

  const isNavigatedBack = currentStep <= maximumStepReached && hasNavigatedBack;

  const { data, refetch } = useQuery({
    queryKey: ['getContentResponsesPoll', slideId],
    queryFn: () => getContentResponses(slideId),
    enabled: false,
  });

  const handleOpenQuestionResponse = useCallback(
    (event: CustomMessageEvent) => {
      const body = event.message.body;
      const action = event.message.action;

      if (
        body.type === SlideBodyType.ACTIVITY &&
        body.activity_type === ActivitySubtype.OPEN_QUESTION &&
        body.activity_id === slideId &&
        action === SlideActionType.ACTIVITY
      ) {
        const studentId = students.find(
          student => student.id.toString() === event.publisher
        );
        const studentName = `${studentId?.firstName} ${studentId?.lastName}`;

        setResponse(responses => [
          ...responses,
          {
            id: body.answer.id,
            username: studentName,
            response: body.answer.response,
            likes: { count: 0, userIds: [] },
          },
        ]);
      }
    },
    [slideId, students]
  );

  const handleLikeUpdate = useCallback(
    (event: CustomMessageEvent) => {
      const body = event.message.body;
      const action = event.message.action;

      if (
        action === SlideActionType.UPDATE_LIKE &&
        body.type === SlideBodyType.ACTIVITY &&
        body.activity_type === ActivitySubtype.OPEN_QUESTION &&
        body.activity_id === slideId
      ) {
        setResponse(prevResponses =>
          prevResponses.map(resp => {
            return resp.id === body.answer.id
              ? {
                  ...resp,
                  likes: {
                    ...body.answer.likes,
                  },
                }
              : resp;
          })
        );
      }
    },
    [slideId]
  );

  const handleFavoriteResponses = useCallback(
    (event: CustomMessageEvent) => {
      const body = event.message.body;
      const action = event.message.action;

      if (
        action === SlideActionType.FAVORITE_RESPONSES &&
        body.type === SlideBodyType.ACTIVITY &&
        body.activity_type === ActivitySubtype.OPEN_QUESTION &&
        body.activity_id === slideId
      ) {
        setFavoriteResponses(body.answer);
      }
    },
    [slideId]
  );

  const handleShowChoiceAward = useCallback(
    (event: CustomMessageEvent) => {
      const body = event.message.body;
      const action = event.message.action;

      if (
        action === SlideActionType.SHOW_CLASS_CHOICE &&
        body.type === SlideBodyType.ACTIVITY &&
        body.activity_type === ActivitySubtype.OPEN_QUESTION &&
        body.activity_id === slideId
      ) {
        setShowChoiceAward(true);
      }
    },
    [slideId]
  );

  const sendLikeUpdate = async updatedResponse => {
    const messagePayload = {
      uuid: userId,
      action: SlideActionType.UPDATE_LIKE,
      body: {
        type: SlideBodyType.ACTIVITY,
        activity_type: ActivitySubtype.OPEN_QUESTION,
        answer: updatedResponse,
        activity_id: slideId,
      },
      subscribed_channel: channel,
      actual_channel: channel,
    };

    await pubnub.publish({
      channel: channel,
      message: messagePayload,
    });
  };

  const handleLike = (responseId: string) => {
    let previousLikedResponseId;

    responses.forEach(resp => {
      if (resp.likes.userIds.includes(userId)) {
        previousLikedResponseId = resp.id;
      }
    });

    const updatedResponses = responses.map(resp => {
      if (previousLikedResponseId && resp.id === previousLikedResponseId) {
        const likedResponse = {
          ...resp,
          likes: {
            count: resp.likes.count - 1,
            userIds: resp.likes.userIds.filter(id => id !== userId),
          },
        };
        sendLikeUpdate(likedResponse);
      }

      if (resp.id === responseId) {
        const alreadyLiked = resp.likes.userIds.includes(userId);

        const updatedResponse = {
          ...resp,
          likes: {
            count: alreadyLiked ? resp.likes.count - 1 : resp.likes.count + 1,
            userIds: alreadyLiked
              ? resp.likes.userIds.filter(id => id !== userId)
              : [...resp.likes.userIds, userId],
          },
        };

        sendLikeUpdate(updatedResponse);
        return updatedResponse;
      }

      return resp;
    });
  };

  const sendFavoriteResponses = async (responses: Record<string, string>[]) => {
    const messagePayload: ActivityPayload = {
      uuid: userId,
      action: SlideActionType.FAVORITE_RESPONSES,
      body: {
        type: SlideBodyType.ACTIVITY,
        activity_type: ActivitySubtype.OPEN_QUESTION,
        answer: responses,
        activity_id: slideId,
      },
      subscribed_channel: channel,
      actual_channel: channel,
    };

    await pubnub.publish({
      channel: channel,
      message: messagePayload,
    });
  };

  const sendOpenQuestionResponse = async (response: string) => {
    if (response) {
      const messagePayload: ActivityPayload = {
        uuid: userId,
        action: SlideActionType.ACTIVITY,
        body: {
          type: SlideBodyType.ACTIVITY,
          activity_type: ActivitySubtype.OPEN_QUESTION,
          answer: {
            id: nanoid(),
            response,
          },
          activity_id: slideId,
        },
        subscribed_channel: channel,
        actual_channel: channel,
      };

      await pubnub.publish({
        channel: channel,
        message: messagePayload,
      });
    }
  };

  const sendShowChoiceAward = async (answer: boolean) => {
    if (answer) {
      const messagePayload: ActivityPayload = {
        uuid: userId,
        action: SlideActionType.SHOW_CLASS_CHOICE,
        body: {
          type: SlideBodyType.ACTIVITY,
          activity_type: ActivitySubtype.OPEN_QUESTION,
          answer,
          activity_id: slideId,
        },
        subscribed_channel: channel,
        actual_channel: channel,
      };

      await pubnub.publish({
        channel: channel,
        message: messagePayload,
      });
    }
  };

  useEffect(() => {
    const listenerParams = {
      message: (event: CustomMessageEvent) => {
        handleOpenQuestionResponse(event);
        handleShowChoiceAward(event);
        handleLikeUpdate(event);
        handleFavoriteResponses(event);
      },
    };

    pubnub.addListener(listenerParams);
    //pubnub.subscribe({ channels: [channel] });

    return () => {
      pubnub.removeListener(listenerParams);
    };
  }, [
    pubnub,
    channel,
    handleOpenQuestionResponse,
    handleShowChoiceAward,
    handleFavoriteResponses,
    handleLikeUpdate,
  ]);

  useEffect(() => {
    if (!isNavigatedBack) {
      setResponse([]);
      setFavoriteResponses(undefined);
      setShowChoiceAward(false);
    } else {
      refetch();
    }
  }, [isNavigatedBack, refetch, slideId]);

  const openQuestionResponses = useMemo(() => {
    if (isNavigatedBack && data) {
      const apiResponses = data.map(response => response.body);
      if (apiResponses.length > 0) {
        const localResponses = apiResponses.map(response => {
          return {
            id: response.id,
            response: response.response,
          };
        });

        return localResponses;
      } else {
        return [];
      }
    } else {
      return responses;
    }
  }, [isNavigatedBack, data, responses]);

  return {
    responses: openQuestionResponses,
    sendOpenQuestionResponse,
    sendShowChoiceAward,
    showChoiceAward,
    sendLikeUpdate,
    handleLike,
    favoriteResponses,
    sendFavoriteResponses,
  };
};
