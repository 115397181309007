import { MdCheckCircleOutline, MdOutlineHourglassBottom } from 'react-icons/md';
import { Typography } from '@components/atoms';

interface ReminderBadgeProps {
  isReminderSent: boolean;
}

export const ReminderBadge: React.FC<ReminderBadgeProps> = ({
  isReminderSent,
}) => {
  return (
    <div className='shadow flex w-32 flex-col items-center justify-center rounded-lg border border-purple-200 bg-purple-50 px-1 pt-2'>
      <Typography variant='span' className='!text-secondary-buttons-700'>
        Reminder sent:
      </Typography>
      <div className='flex h-10 w-10 items-center justify-center rounded-full'>
        {isReminderSent ? (
          <MdCheckCircleOutline className='h-6 w-6 text-green-600' />
        ) : (
          <MdOutlineHourglassBottom className='h-6 w-6 text-yellow-600' />
        )}
      </div>
    </div>
  );
};
