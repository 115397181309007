interface Course {
  id: number;
  public_uid: string;
  name: string;
  state: string;
  parish_name: string;
  modules: Modules;
}

interface Reto {
  id: number;
  title: string;
  description: string;
  challenge_type: string;
  due_date: Date;
  points_awarded: number;
  lesson_id: number;
  subtitle: string;
  course_id: string;
}

interface DailyPrayerGame {
  public_uid: string;
  title: string;
  level: number;
  timer: number;
  min_points: number;
  max_points: number;
  image_url: string;
  sentences: {
    id: number;
    sentence: string;
    correct_answer: string;
    incorrect_answer_1: string;
    incorrect_answer_2: string;
    incorrect_answer_3: string;
    position: number;
  }[];
}

export interface Lesson {
  public_uid: string;
  title: string;
  description: string;
  module_public_uid: string;
  course_public_uid: string;
}

interface InputData {
  courses: Course[];
  retos: Reto[];
  daily_prayer_game: DailyPrayerGame;
  last_time_played: string | null;
  lesson: Lesson;
}

interface TransformedCourse {
  id: number;
  publicUid: string;
  name: string;
  state: string;
  parishName: string;
}

interface Modules {
  total: number;
  finished: number;
}

interface TransformedReto {
  id: number;
  description: string;
}

interface TransformedLesson {
  publicUid: string;
  title: string;
  description: string;
  modulePublicUid: string;
  coursePublicUid: string;
}

interface OutputData {
  courses: TransformedCourse[];
  retos: TransformedReto[];
  dailyPrayerGame: DailyPrayerGame;
  lastTimePlayed: string | null;
  lesson: TransformedLesson | null;
}

export const homeAdapter = (input: InputData): OutputData => {
  const transformedCourses: TransformedCourse[] =
    input.courses.length > 0
      ? input.courses.map(course => ({
          id: course.id,
          publicUid: course.public_uid,
          name: course.name,
          state: course.state,
          parishName: course.parish_name,
          modules: {
            total: course.modules.total,
            finished: course.modules.finished,
          },
        }))
      : [];

  const transformedRetos: TransformedReto[] = input.retos.map(reto => ({
    id: reto.id,
    description: reto.description,
    dueDate: reto.due_date,
    classId: reto.course_id,
  }));

  const transformedLesson =
    Object.keys(input.lesson).length > 0
      ? {
          publicUid: input.lesson.public_uid,
          title: input.lesson.title,
          description: input.lesson.description,
          modulePublicUid: input.lesson.module_public_uid,
          coursePublicUid: input.lesson.course_public_uid,
        }
      : null;

  return {
    courses: transformedCourses,
    retos: transformedRetos,
    dailyPrayerGame: input.daily_prayer_game,
    lastTimePlayed: input.last_time_played,
    lesson: transformedLesson,
  };
};
