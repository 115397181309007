import { Content, UserRole } from '@domain/constants';
import { SlideContentWrapper } from '../SlideContentWrapper';
import {
  PollActivityInput,
  PollActivityResponses,
} from '@components/molecules';
import { usePoll } from '@hooks/index';
import { useState } from 'react';
import { PreviewModeProps } from '@store/slices';

interface RenderPollActivityProps {
  slide: Content;
  role: UserRole;
  channel: string;
  showResults: boolean;
  previewMode: PreviewModeProps;
}

export const RenderPollActivity = ({
  slide,
  role,
  channel,
  showResults,
  previewMode,
}: RenderPollActivityProps) => {
  // Only use PubNub hook if not in preview mode
  const { pollResponses, sendPollResponse } = previewMode.isPreviewMode
    ? {
        pollResponses: [],
      }
    : usePoll({
        channel,
        slideId: slide.id,
      });

  const [selectedOption, setSelectedOption] = useState<string>('');

  const handleOptionClick = (option: string) => {
    if (previewMode.isPreviewMode) {
      setSelectedOption(option);
    } else {
      sendPollResponse(option);
    }
  };

  return (
    <SlideContentWrapper
      title={slide.poll!.question}
      type={slide.type}
      subtype={slide.subtype}
    >
      {(role === UserRole.CATECHIST && !previewMode.isPreviewMode) ||
      (previewMode.isPreviewMode && !previewMode.isStudentView) ? (
        <PollActivityResponses
          data={pollResponses}
          options={slide.poll!.options}
          showCorrectAnswer={true}
        />
      ) : !showResults || previewMode.isStudentView ? (
        <PollActivityInput
          options={slide.poll!.options}
          onClickOption={handleOptionClick}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
      ) : (
        <PollActivityResponses
          data={pollResponses}
          options={slide.poll!.options}
          showCorrectAnswer={true}
        />
      )}
    </SlideContentWrapper>
  );
};
